<template>
  <v-container class="tempRegister">
    <div class="mt-12">
      <h3 class="text-center">{{ $t("message.title-tajimingo-new-member") }}</h3>
      <v-divider style="background-color: black"></v-divider>
      <p class="">{{ $t("message.label-login-email") }}</p>
      <p class="pt-2">{{ $t("message.label-email-confirm") }}</p>
    </div>

    <v-col class="pr-2 pb-2 pt-12" cols="12">
      <form @submit.prevent="submitTempRegis">
        <v-row>
          <v-col cols="12" md="2" xs2 class="label-class" style="padding-bottom: 0px">
            <span style="color: black;">{{ $t("message.label-please-enter-email") }}</span>
          </v-col>
          <v-col cols="12" md="10" xs2 class="input-class">
            <v-text-field
              outlined
              background-color="white"
              v-model="email"
              :error-messages="emailErrors"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              required
            />
          </v-col>
        </v-row>
        <div class="d-flex flex-row justify-center align-center mt-3 me-0" tile>
          <div class="m-2">
            <v-btn
              depressed
              color="#E93B81"
              type="submit"
              @click.prevent="submitTempRegis"
              height="45"
              width="250"
              :loading="isLoadingBtn"
              :disabled="isLoadingBtn"
              >
              <span style="color: white">{{$t("message.label-temp-register")}}</span>
            </v-btn>
          </div>
        </div>
      </form>
    </v-col>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

import { mdiCloseThick } from "@mdi/js";
import Swal from 'sweetalert2';
export default {
  name: "TempRegister",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  data() {
    return {
      closePage: mdiCloseThick,
      email: null,
      err: null,
      // isLoading: false,
      isLoadingBtn: false
    };
  },
  methods: {
    submitTempRegis() {
      this.isLoadingBtn = true
      if(!this.email) {
        Swal.fire('Email is required')
        return false
      }
      if(this.err.length > 0) {
        Swal.fire(this.err[0])
        return false
      }

      let payload = {
        email: this.email,
        url_redirect: window.location.origin+process.env.BASE_URL+'term-of-service'
      }
      this.$store.dispatch("auth_module/tempRegister", payload);
      // this.$router.push("/register");
      this.isLoadingBtn = false
    },
  },
  computed: {
    emailErrors() {
      let errors = [];
      if (!this.$v.email.$dirty) return errors;
      // !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.email && errors.push(this.$t("message.err-msg-email"));
      !this.$v.email.required && errors.push(this.$t("message.err-msg-email-required"));
      this.err = errors
      return errors;
    },
  }
};
</script>

<style>
.tempRegister {
  background-color: #fff;
  height: 100vh;
  max-width: 100vw !important;
}
</style>
